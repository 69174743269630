<template>
  <b-card>
    <h2 class="mb-2">Dados da turma</h2>
    <b-overlay
      :show="isLoading"
      opacity="0.70"
      variant="transparent"
      rounded="sm"
    >
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="2"
          xl="1"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="require('@/assets/images/pages/exams.svg')"
              class="bg-avatar bg-color-avatar"
              size="80px"
              rounded
            />
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col lg="5">
          <table class="mt-2 mt-xl-0 w-98">
            <tr>
              <th class="pb-50">
                <feather-icon icon="CreditCardIcon" class="mr-75" />
                <span class="font-weight-bold mr-1">Turma:</span>
              </th>
              <td class="pb-50 text-capitalize font-weight-bold">
                {{ classShow ? classShow.name : "" }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="HomeIcon" class="mr-75" />
                <span class="font-weight-bold">Série:</span>
              </th>
              <td class="pb-50 text-capitalize font-weight-bold">
                {{ classShow ? classShow.series : "" }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="ListIcon" class="mr-75" />
                <span class="font-weight-bold">Período:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ classShow ? classShow.time_course : "" }}
              </td>
            </tr>
          </table>
        </b-col>
        <!-- Endereço -->
        <b-col>
          <table class="mt-2 mt-xl-0 w-98">
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold mr-1">Data de cadastro:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ classShow && classShow.created_at | dateFormat }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold mr-1"
                  >Data da última atualização:</span
                >
              </th>
              <td class="pb-50 text-capitalize">
                {{ classShow && classShow.updated_at | dateFormat }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="BookIcon" class="mr-75" />
                <span class="font-weight-bold mr-1">Selecione o livro ativo:</span>
              </th>
              <td class="pb-50 text-capitalize">
                <b-form-group label="Livros">
                  <b-form-radio-group
                    id="books_set_default"
                    v-model="active_book_id"
                    name="books_set_default"
                    value-field="id"
                    text-field="name"
                    :options="classShow.books"
                  />
                </b-form-group>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col>
          <h3 class="md-10 mt-2">Lista de alunos</h3>
        </b-col>

        <b-col md="2 mt-2">
          <b-button
            v-b-toggle.sidebar-create-students
            size="sm"
            block
            variant="relief-primary"
            @click="addNew"
          >
            Novo aluno
            <feather-icon icon="GridIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        striped
        stacked="md"
        class="mt-2 responsive text-center"
        id="tableClass"
        v-if="classShow"
      >
        <b-thead>
          <b-tr class="text-center">
            <b-th>CGM</b-th>
            <b-th>NOME</b-th>
            <b-th>DATA NASCIMENTO</b-th>
            <b-th>SEXO</b-th>
            <b-th>SITUAÇÃO</b-th>
            <b-th>DATA ÚLT. ATUALIZAÇÃO</b-th>
            <b-th>RESPONSÁVEL</b-th>
            <b-th>AÇÂO</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr :key="indextr" v-for="(tr, indextr) in classShow.students">
            <b-td>
              {{ tr.cgm }}
            </b-td>
            <b-td>
              {{ tr.name }}
            </b-td>
            <b-td>
              {{ tr.birth_date | dateFormat }}
            </b-td>
            <b-td>
              {{ tr.sex }}
            </b-td>
            <b-td>
              {{ tr.enrollment_status }}
            </b-td>
            <b-td>
              {{ tr.enrollment_date | dateFormat }}
            </b-td>
            <b-td class="text-center">
              <!-- <span :key="indextr2" v-for="(tr2, indextr2) in tr.responsibles">
                <b-link @click="updateResponsible(tr2.id)">
                  <b-badge
                    class="p-1"
                    style="margin: 3px; margin-right: 0"
                    variant="light-success"
                  >
                    {{ tr2.name }}
                  </b-badge>
                </b-link>
              </span> -->
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-primary"
                pill
                size="sm"
                @click="addNewResponsible(tr.id)"
                v-if="tr.responsibles.length == 0"
              >
                <feather-icon icon="UserPlusIcon" />
                Adicionar responsável
              </b-button>
              <b-button-group
                size="sm"
                v-else
              >
                <b-button
                  :key="indextr2" v-for="(tr2, indextr2) in tr.responsibles"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="updateResponsible(tr2.id)"
                >
                   {{ tr2.name }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-primary"
                  @click="addNewResponsible(tr.id)"
                >
                  <feather-icon icon="UserPlusIcon" />
                </b-button>
              </b-button-group>
            </b-td>
            <b-td class="text-center">
              <b-button
                v-b-toggle.sidebar-create-students
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-dark"
                class="btn-icon rounded-circle"
                @click="updateStudent(tr.id)"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-modal.modal-delete-student
                variant="flat-dark"
                class="btn-icon rounded-circle"
                @click="updateStudent(tr.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <!-- Modal Delete -->
    <b-modal
      id="modal-delete-responsible"
      ok-variant="danger"
      modal-class="modal-danger"  
      ref="modal-delete-responsible"
      centered
      title="Deletar"
      v-if="responsibleModel != null"
    >
      <b-card-text>
        <p>Você está preste a deletar este responsável: <b>{{responsibleModel.name}}</b></p>
        Por favor, confirme a exclusão antes de prosseguir.
        <b-form-checkbox
          id="checkbox-1"
          @change="newDeleteResponsibleStatus"
          v-model="DeleteResponsibleStatus"
          name="checkbox-1"
          value="accepted"
          unchecked-value="null"
        >
          Concordo em excluir este responsável
        </b-form-checkbox>  
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-danger"
            block
            :disabled="DeleteResponsibleStatus === 'null'"
            @click="deleteResponsible(responsibleModel.id)"
          >
            Deletar
          </b-button>
        </b-col>
      </template>
    </b-modal>
      <!-- Modal Delete -->
      <b-modal
        id="modal-delete-student"
        ref="modal-delete-student"
        ok-variant="danger"
        modal-class="modal-danger"
        centered
        title="Deletar Aluno"
      >
        <b-card-text v-if="sidebarStudentData != null">
          <p>Você está preste a deletar este aluno: <b>{{sidebarStudentData.name}}</b></p>
          Por favor, confirme a exclusão antes de prosseguir.
          <b-form-checkbox
            id="checkbox-1"
            v-model="DeleteStatus"
            @change="newDeleteStatus"
            name="checkbox-1"
            value="accepted"
          >
            Concordo em excluir este aluno
          </b-form-checkbox>
        </b-card-text>
        <template #modal-footer>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-2"
              variant="outline-danger"
              block
              :disabled="DeleteStatus == false"
              @click="deleteStudent(sidebarStudentData.id)"
            >
              Deletar Aluno
            </b-button>
          </b-col>
        </template>
      </b-modal>
    </b-overlay>
    <create-edit-student
      :dataProp="sidebarStudentData"
      @fetch-details="fetchDetails"
    />
    <b-modal
      id="modal-create-edit-responsible"
      ref="modal-create-edit-responsible"
      size="lg"
    >
      <b-form method="POST">
            <validation-observer ref="simpleRules">
              <b-form-input class="hidden" name="_token" :value="csrf" />
              <b-form-group label="Nome:" label-for="i-name-r" class="pl-1 pr-1">
                <validation-provider
                  #default="{ errors }"
                  name="nome"
                  rules="required"
                >
                  <b-form-input
                    id="i-name-r"
                    v-model="dataResponsible.name"
                    placeholder="Ex: João"
                    name="name-r"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Email:"
                label-for="i-email-r"
                class="pl-1 pr-1"
              >
                <b-form-input
                    id="i-email-r"
                    v-model="dataResponsible.email"
                    placeholder="Ex: email@responsavel.com"
                    name="email"
                    class="form-control"
                    type="email"
                  /> 
              </b-form-group>
              <b-form-group
                label="Telefone:"
                label-for="i-phone-r"
                class="pl-1 pr-1"
              >
                  <b-form-input
                    id="i-phone-r"
                    v-model="dataResponsible.phone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    placeholder="Ex: (45) 99999-9999"
                  />
              </b-form-group>
              <b-row style="margin-right: 0rem !important">
                <b-col class="ml-1">
                  <b-button
                    block
                    class="d-block"
                    variant="relief-primary"
                    @click="storeResponsible()"
                    :disabled="dataResponsible.name == null"
                  >
                    {{ dataResponsible.id == null ? "Salvar" : "Editar" }}
                  </b-button>
                </b-col>
                <b-col class="ml-1" v-if="dataResponsible.id">
                  <b-button
                    v-b-modal.modal-delete-responsible
                    :disabled="dataResponsible.name == null"
                    block
                    class="d-block"
                    variant="relief-danger"
                    @click="showDetailDelete(dataResponsible)"
                  >
                    Remover
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    @click="closeModal()"
                    block
                  >
                    Fechar
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </b-form>

      <template #modal-footer>
        <b-col>
        </b-col>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import CreateEditStudent from "../student/CreateEdit";
import CreateEditResponsible from "../responsible/CreateEdit";
import {
  required, 
  email
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import student from '@/store/student';

export default {
  components: {
    CreateEditStudent,
    CreateEditResponsible,
    ValidationProvider,
    ValidationObserver,
    BCard,
     vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      isLoading: true,
      isLoadingStore: false,
      school: null,
      active_book_id: null,

      sidebarStudentData: {},
      dataResponsible: {
        name: null,
        email: null,
        phone: null,
      },
      responsibleModel: {},
      DeleteStatus: false,
      DeleteResponsibleStatus: false,

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',

      perPageOptions: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
      ],
    }
  },
  computed: {
    ...mapState("classes", ["classShow"]),
  },
  methods: {
    ...mapActions("responsibles", ["store", "update"]),
    ...mapActions("classes", ["setDefaultBook"]),
    fetchDetails(paginated = false, page = 1){
      this.isLoading = true
      this.$store.dispatch("classes/fetchDetailsClass", {
          class_id: this.$route.params.class_id,
          paginated: paginated,
          page: page,
        })
        .then((response) => {
          this.school = response.school_id
        })
        .finally(() => {
          this.isLoading = false
        });
    },
    showDetailDelete(model){
      this.responsibleModel = model
    },
    newDeleteStatus(value){
      this.DeleteStatus = value
    },
    newDeleteResponsibleStatus(value){
      this.DeleteResponsibleStatus = value
    },
    updateStudent(id){
      this.sidebarStudentData = this.$store.getters['classes/getStudentById'](id)
    },
    addNew() {
      this.sidebarStudentData = {
        class_id: this.$route.params.class_id,
        school_id: this.school
      }; 
    },
    addNewResponsible(student_id){
      this.dataResponsible = {
        name: null,
        phone: null,
        email: null,
        student_id: student_id
      };
      this.$refs['modal-create-edit-responsible'].show();
    },
    updateResponsible(responsible_id) {
      this.dataResponsible = this.$store.getters['classes/getResponsibleById'](responsible_id)
      this.$refs['modal-create-edit-responsible'].show()
    },
    storeResponsible() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoadingStore = true
          if (this.dataResponsible.id == null) { 
            let data = new Date()
            this.store(this.dataResponsible).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cadastrado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                }
              })
              this.initValues()
            })
            .finally(() => {
              this.fetchDetails();
              this.isLoadingStore = false;
              this.closeModal()
            })
          } else {
            this.update(this.dataResponsible).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Atualizado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'info'
                }
              })

              this.initValues();
            })
            .finally(() => {
              this.fetchDetails();
              this.isLoadingStore = false
              this.closeModal();
            })
          }
        }else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    },
    initValues(){
      this.dataResponsible = {
        id: null,
        name: null,
        email: null,
        phone: null,
        student_id: null,
      }
      this.$nextTick(() => this.$refs.simpleRules.reset());
    },
    closeModal(){
      this.$refs['modal-create-edit-responsible'].hide()
    },
    deleteResponsible(id){
      this.isLoading = true;
      let data = id
      this.$store
        .dispatch("responsibles/delete", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.isLoading = false
          this.DeleteResponsibleStatus = null
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.closeModal();
          this.fetchDetails()
          this.$refs['modal-delete-responsible'].hide()
        })
    },
    deleteStudent(id){
      this.isLoading = true;
      let data = id
      this.$store
        .dispatch("students/delete", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.isLoading = false
          this.DeleteStatus = null
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.closeModal();
          this.fetchDetails()
          this.$refs['modal-delete-student'].hide()
        })
    },
  },
  created(){
    this.fetchDetails();
  },
  watch: {
    perPage(){
      //this.showBook()
    },
    classShow(){
      let active_book = this.classShow.books.filter(book => book.pivot.status === 'active')
      this.active_book_id = active_book[0]?.id
    },
    active_book_id(newVal, oldVal){
      if(oldVal != null){
        this.setDefaultBook({
        	"book_id" : newVal,
	        "class_id" : this.classShow.id
        }).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Livro padrão alterado com sucesso!',
              icon: 'ThumbsUpIcon',
              text: 'Operação executada com sucesso.',
              variant: 'success'
            }
          })
          this.isLoading = false
          this.DeleteStatus = null
        })
      }
    }
  }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.bg-color-avatar {
  background: none !important;
}
</style>
