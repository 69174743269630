<template>
  <div>
    <b-sidebar
      id="sidebar-create-students"
      ref="sidebar-create-students"
      no-close-on-backdrop
      no-close-on-esc
      shadow
      right
      bg-variant="white"
      backdrop
      :title="dataProp.id == null ? 'Cadastrar aluno' : 'Editar aluno'"
    >
      <div>
        <hr />
        <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
          <b-tabs content-class="mt-2" align="center">
            <b-tab active>
              <template #title>
                <span>{{dataProp.id == null ? 'Cadastrar' : 'Editar'}}</span>
              </template>
              <div>
                <b-form method="POST">
                  <validation-observer ref="simpleRules">
                  <b-form-input class="hidden" name="_token" :value="csrf" />
                  <b-form-group
                    label="Nome:"
                    label-for="i-student-name"
                    class="pl-1 pr-1"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="nome"
                      rules="required"
                    >
                    <b-form-input
                      id="i-student-name"
                      placeholder="Ex: João"
                      v-model="dataStudent.name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Email:"
                    label-for="i-email-student"
                    class="pl-1 pr-1"
                  >
                    <b-form-input
                      id="email"
                      v-model="dataStudent.email"
                      placeholder="Ex: emailempresa@gmail.com"
                      name="i-email-student"
                      class="form-control"
                      type="email"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Data de nascimento:"
                    label-for="i-birth"
                    class="pl-1 pr-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="data de nascimento"
                      rules="required"
                    >
                      <b-form-input
                        id="i-birth"
                        type="date"
                        placeholder="Ex: 01/01/2020"
                        v-model="dataStudent.birth_date"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Sexo.:"
                    label-for="i-sex-group"
                    class="pl-1 pr-1"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="sexo"
                      rules="required"
                    >
                    <b-form-select
                        id="i-sex1"
                        v-model="dataStudent.sex"
                        :options="sex_option"
                        value-field="id"
                        text-field="name"
                        class="form-control"
                        :state="errors.length > 0 ? false:null"
                      />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Situação.:"
                    label-for="i-enrollment_status"
                    class="pl-1 pr-1"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="situação"
                      rules="required"
                    >
                    <b-form-select
                        id="i-sex2"
                        v-model="dataStudent.enrollment_status"
                        :options="enrollment_status_option"
                        value-field="id"
                        text-field="name"
                        class="form-control"
                        :state="errors.length > 0 ? false:null"
                      />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
                  <div v-if="this.dataStudent.id == null">
                    <hr />
                    <b-form-group
                    label="CGM (Usuário):"
                    label-for="i-cgm"
                    class="pl-1 pr-1"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="cgm"
                        rules="required"
                      >
                      <b-form-input
                        id="i-cgm"
                        placeholder="EX: 908070"
                        v-model="dataStudent.cgm"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                    
                    <b-form-group
                      label="Senha:"
                      label-for="i-password"
                      class="pl-1 pr-1"
                    >
                       <validation-provider
                        #default="{ errors }"
                        name="senha"
                        rules="required"
                      >
                        <b-form-input
                          id="password"
                          v-model="dataStudent.password"
                          placeholder="**************"
                          name="i-password"
                          class="form-control"
                          type="password"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <b-row style="margin-right: 0rem !important;">
                    <b-col lg="3" sm="6" class="mr-1 ml-1">
                      <b-button
                        variant="relief-primary"
                        @click="storeStudent()"
                        :disabled="dataStudent.name == null"
                      >
                        {{this.dataStudent.id == null ? 'Salvar' : 'Editar'}}
                      </b-button>
                    </b-col>
                    <b-col>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        @click="closeSidebar()"
                        block
                      >
                        Fechar
                      </b-button>
                    </b-col>
                  </b-row>
                  </validation-observer>
                </b-form>
              </div>
            </b-tab>
            <b-tab>
              <template #title>
                <span>Importar</span>
              </template>
              <div>
                <b-form method="POST">
                  <validation-observer ref="simpleRulesImport">
                    <b-form-input class="hidden" name="_token" :value="csrf" />
                    <b-form-group 
                      label="* Turma:" 
                      label-for="i-school-import"
                      class="pl-1 pr-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="escola"
                        rules="required"
                      > 
                        <b-form-select
                          id="i-school-import"
                          v-model="dataImport.class_id"
                          :disabled="true"
                          :options="classes"
                          value-field="id"
                          text-field="name"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Arquivo:"
                      label-for="i-file"
                      class="pl-1 pr-1"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="arquivo"
                        rules="required"
                      >
                      <b-form-file
                        placeholder="Arquivo"
                        v-model="dataImport.file"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        name="file"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                    <b-row style="margin-right: 0rem !important;">
                      <b-col sm="5" class="ml-1">
                        <b-button
                          variant="relief-primary"
                          @click="importStudents()"
                          :disabled="dataImport.file == null"
                          block
                        >
                          Importar
                        </b-button>
                      </b-col>
                      <b-col>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        @click="closeSidebar()"
                        block
                      >
                        Cancelar
                      </b-button>
                    </b-col>
                  </b-row>
                  </validation-observer>
                  <b-link class="mt-2 d-block text-center" href="/alunos_por_turma.xlsx" target="_blank">
                    <feather-icon icon="DownloadIcon" class="mr-1" />Baixar arquivo de exemplo
                  </b-link>
                </b-form>
              </div>
            </b-tab>
          </b-tabs>
        </b-overlay>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {mask} from 'vue-the-mask'
import {
  required, 
  email
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { password } from '@/@core/utils/validations/validations';

export default {
  props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    BButton,
    BSidebar,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    mask,
  },
  data() {
    return {
      icon: null,
      dataImport: {
        class_id: null,
        disabled_class_id: false,
        file: null,
      },

      showSidebar: false,

      dataStudent: {
        class_id: null,
        cgm: null,
        name: null,
        birth_date: null,
        sex: null,
        enrollment_status: 'Matriculado',
        email: null,
        username: null,
        password: null,
      },
      sex_option: [
        { id: "Masculino", name: "Masculino" },
        { id: "Feminino", name: "Feminino" },
      ],
      enrollment_status_option: [
        { id: "Matriculado", name: "Matriculado" },
        { id: "Transferido", name: "Transferido" },
      ],
      isLoadingInep: false,
      isLoadingStore: false,
      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    }
  },
  computed: {
    ...mapState("classes", ["classes"]),
  },
  methods: {
    ...mapActions("students", ["store", "update", "import"]),
    initValues(){
      this.dataStudent = {
        class_id: null,
        cgm: null,
        name: null,
        birth_date: null,
        sex: null,
        enrollment_status: "Matriculado",
        email: null,
        school_id: null,
        // username: null,
        password: null,
      }
      this.dataImport = {
        class_id: null,
        file: null,
      }

      this.$nextTick(() => this.$refs.simpleRules.reset());
    },
    importStudents(){
      this.$refs.simpleRulesImport.validate().then(success => {
        if (success) {
          this.isLoadingStore = true
          this.import(this.dataImport).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Importado com sucesso!',
                icon: 'ThumbsUpIcon',
                text: 'Operação executada com sucesso.',
                variant: 'success'
              }
            })
            this.dataImport.class_id = null;
            this.dataImport.file = null;
            this.$nextTick(() => this.$refs.simpleRulesImport.reset());
            this.fetchClasses(); 
          }).finally(() => {
            this.$emit('fetch-details');
            this.isLoadingStore = false
            this.closeSidebar()
          })
        }
      });
    }, 
    storeStudent() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoadingStore = true
          if (this.dataStudent.id == null) { 
            let data = new Date()
            this.store({ ...this.dataStudent, from: 'show_class', username: this.dataStudent.cgm, }).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cadastrado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                }
              })
              this.initValues()
              this.$emit('fetch-details');
              this.closeSidebar()
            })
            .finally(() => {
              this.isLoadingStore = false;
              
            })
          } else {
            this.update(this.dataStudent).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Atualizado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'info'
                }
              })
              this.initValues();
              this.$emit('fetch-details');
              this.closeSidebar();
            })
            .finally(() => {
              this.isLoadingStore = false
            })
          }
        }else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    },

    fetchClasses(paginated = false, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("classes/fetchClasses", {
          paginated: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    closeSidebar(){
      this.$refs['sidebar-create-students'].hide()
    }
  },
  watch: {
  dataProp: {
    // the callback will be called immediately after the start of the observation
    immediate: true, 
    handler (val, oldVal) {
      if (!val) return; 
      this.initValues(); 
      if (Object.entries(this.dataProp).length === 0 || this.dataProp.id == null) { 
        let { class_id, school_id } = JSON.parse(JSON.stringify(this.dataProp));
        this.dataStudent.class_id = class_id;
        this.dataStudent.school_id = school_id;
        this.dataImport.class_id = class_id;
      } else { 
        let { id, class_id, name, cgm, sex, birth_date, enrollment_status, school_id } = JSON.parse(JSON.stringify(this.dataProp));
        this.dataImport.class_id = class_id;

        this.dataStudent.id = id;
        this.dataStudent.class_id = class_id;
        this.dataStudent.name = name;
        this.dataStudent.cgm = cgm;
        this.dataStudent.sex = sex;
        this.dataStudent.birth_date = birth_date;
        this.dataStudent.enrollment_status = enrollment_status;
        this.dataStudent.school_id = school_id;
      } 
    }
  }
  },
  created() {
    this.fetchClasses();
  },
}
</script>