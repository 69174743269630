<template>
  <div>
    <b-sidebar
      id="sidebar-create-responsible"
      ref="sidebar-create-responsible"
      shadow
      right
      bg-variant="white"
      backdrop
      :title="
        Object.entries(this.dataProp).length === 0
          ? 'Cadastrar responsável'
          : 'Editar responsável'
      "
    >
      <div>
        <hr />
        <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
          <b-form method="POST">
            <validation-observer ref="simpleRules">
              <b-form-input class="hidden" name="_token" :value="csrf" />
              <b-form-group label="Nome:" label-for="i-name-res" class="pl-1 pr-1">
                <validation-provider
                  #default="{ errors }"
                  name="nome"
                  rules="required"
                >
                  <b-form-input
                    id="i-name-res"
                    placeholder="Ex: João"
                    v-model="dataResponsible.name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Email:"
                label-for="i-email-r"
                class="pl-1 pr-1"
              >
                <b-form-input
                  id="i-email-r"
                  v-model="dataResponsible.email"
                  placeholder="Ex: email@responsavel.com"
                  name="email"
                  class="form-control"
                  type="email"
                />
              </b-form-group>
              <b-form-group
                label="Telefone:"
                label-for="i-phone-r"
                class="pl-1 pr-1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="telefone"
                  rules="required"
                >
                  <b-form-input
                    id="i-phone-r"
                    v-model="dataResponsible.phone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    placeholder="Ex: (45) 99999-9999"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-row style="margin-right: 0rem !important">
                <b-col class="ml-1">
                  <b-button
                    block
                    class="d-block"
                    variant="relief-primary"
                    @click="storeResponsible()"
                    :disabled="dataResponsible.name == null"
                  >
                    {{ this.dataResponsible.id == null ? "Salvar" : "Editar" }}
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    @click="closeSidebar()"
                    block
                  >
                    Fechar
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </b-form>
        </b-overlay>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {mask} from 'vue-the-mask'
import {
  required, 
  email
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { password } from '@/@core/utils/validations/validations';

export default {
  props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    BButton,
    BSidebar,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    mask,
  },
  data() {
    return {
      icon: null,
      dataResponsible: {
        student_id: null,
        name: null,
        email: null,
        phone: null,
      },
      isLoadingStore: false,
      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    }
  },
  computed: {
    //...mapState("classes", ["classes"]),
  },
  methods: {
    ...mapActions("responsibles", ["store", "update"]),
    initValues(){
      this.dataResponsible = {
        id: null,
        name: null,
        email: null,
        phone: null,
        student_id: null,
      }

      this.$nextTick(() => this.$refs.simpleRules.reset());
    },
    storeResponsible() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoadingStore = true
          if (this.dataResponsible.id == null) { 
            let data = new Date()
            this.store(this.dataResponsible).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cadastrado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                }
              })
              this.initValues()
            })
            .finally(() => {
              this.$emit('fetch-details');
              this.isLoadingStore = false;
              this.closeSidebar()
            })
          } else {
            this.update(this.dataResponsible).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Atualizado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'info'
                }
              })

              this.initValues();
            })
            .finally(() => {
              this.$emit('fetch-details');
              this.isLoadingStore = false
              this.closeSidebar();
            })
          }
        }else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    },

    closeSidebar(){
      this.$refs['sidebar-create-responsible'].hide()
    }
  },
  watch: {
  dataProp: {
    // the callback will be called immediately after the start of the observation
    immediate: true, 
    handler (val, oldVal) {
      if (!val) return; 
      this.initValues(); 
      if (Object.entries(this.dataProp).length === 0 || this.dataProp.id == null) { 
        let { student_id } = JSON.parse(JSON.stringify(this.dataProp));
        this.dataResponsible.student_id = student_id;
      } else { 
        let { id, student_id, name, email, phone } = JSON.parse(JSON.stringify(this.dataProp));
        this.dataResponsible.id = id;
        this.dataResponsible.student_id = student_id;
        this.dataResponsible.name = name;
        this.dataResponsible.email = email;
        this.dataResponsible.phone = phone;
      } 
    }
  }
  },
  created() {

  },
}
</script>